<template>
  <component
    :is="data?.outer_link ? 'a' : NuxtLink"
    :to="getUrl"
    v-bind="data?.outer_link ? { href: getUrl } : { to: getUrl }"
    :target="data?.outer_link && '_blank'"
    class="group flex rounded-[12px] text-new-black shadow-drop-0"
    :class="[
      {
        'flex-col gap-[16px] shadow-none':
          (!isTitleCard || isMobile) && !disablePreview,
      },
      {
        'mb-6 flex-col rounded-none border-b border-new-card--border pb-3 shadow-none':
          disablePreview,
      },
    ]"
  >
    <MainPageCardsReworkedCommonCardImageBlock
      v-if="!disablePreview"
      v-bind="{ data }"
      :aspect="aspect"
      class="relative w-full"
      :class="{ 'pointer-events-none': data?.outer_link }"
      @click.prevent="disablePlay ? router.push(getUrl) : emit('open:video')"
    >
      <div
        v-if="!disablePlay"
        class="absolute left-1/2 top-1/2 z-40 -translate-x-1/2 -translate-y-1/2"
      >
        <SvgIcon name="play-circle-2" class="size-[54px] text-white-100" />
      </div>
    </MainPageCardsReworkedCommonCardImageBlock>

    <div
      v-if="disablePreview"
      class="z-20 mb-[4px] w-fit rounded-r-[4px] py-px pl-[12px] pr-[4px] text-[14px] font-bold uppercase leading-[17px] text-white-100"
      :class="getDataByModel?.labelBgColor"
    >
      <slot name="badge">
        {{ data?.card_label || getDataByModel?.label }}
      </slot>
    </div>

    <div
      class="flex flex-col"
      :class="{ 'w-full p-[36px] md:w-5/12': isTitleCard }"
    >
      <h2
        class="z-30 font-roboto font-medium"
        :class="[
          {
            'mt-[12px] text-[20px] leading-[24px] text-new-test-black':
              disablePreview,
          },
          {
            'text-[18px] 2xl:text-[28px] 2xl:leading-[40px]':
              isTitleCard && !disablePreview,
          },
          {
            'text-center text-[16px] 2xl:text-[22px] 2xl:leading-[26px]':
              !isTitleCard && !disablePreview,
          },
        ]"
      >
        {{ data.title }}
      </h2>

      <p
        v-if="data?.announce && (isTitleCard || disablePreview)"
        class="text-clamp-3 z-30"
        :class="[
          {
            'mt-[16px] text-[14px] 2xl:text-[20px] 2xl:leading-[30px]':
              !disablePreview,
          },
          {
            'mt-[12px] text-[14px] leading-[18px] text-new-card--title-black':
              disablePreview,
          },
        ]"
        v-html="truncTags(data.announce)"
      ></p>
    </div>
  </component>
</template>

<script setup>
const NuxtLink = resolveComponent('NuxtLink');
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  isTitleCard: { type: Boolean, default: false },
  disablePlay: { type: Boolean, default: false },
  disablePreview: { type: Boolean, default: false },
  aspect: {
    type: Object,
    default: () => ({
      w: 16,
      h: 9,
    }),
  },
});

const emit = defineEmits(['open:video']);

const router = useRouter();
const { isMobile } = useBreakpoint();
const getCardSettings = useCardSettings();
const { truncTags } = useUtils();

const getDataByModel = computed(() => getCardSettings(props.data));

const getUrl = computed(() => {
  return (
    props.data?.outer_link ??
    props.data?.url ??
    `${getDataByModel?.value?.hrefBase}${props.data?.slug}`
  );
});
</script>
